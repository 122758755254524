import {environment} from "@ais-no/env";

const urlPrefix = {
  value: environment.useSSO ? environment.ssoEndpoints.urlPrefix : '',
  setClient() {
    this.value = '/identity/client'
  },
  setServerResource() {
    this.value = '/identity/resource-server'
  }
}

export default urlPrefix;
