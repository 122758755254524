// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaults: {
    language: 'en-US',
    project: 'AIS_OFD',
    mainColor: 'green',
  },
  fnExpirationWarningDays: 30,
  shared: {
    services: {
      company: {
        preregisterEndpoint: (tin) => `/web-control/service-api/preregister/${tin}`,
      },
      cashRegister: {
        cashRegisterListEndpoint: (id?) => `/web-control/api/lk/cash-register`,
        cashRegisterStatsEndpoint: (from, to) => `/web-control/api/lk/cash-register/stats/from/${from}/to/${to}`,
        agentCashRegistersEndpoint: (id: string) => `/web-control/api/lk/legal-person/agent-cash-registers`,
      },
      shift: {
        commonEndpoint: '/web-control/api/lk',
      },
      receipt: {
        commonEndpoint: '/web-control/api/lk',
        openApiCommonEndpoint: '/web-control/open/',
        taxRatesApi: '/web-control/open/tax-rates'
      },
      stat: {
        commonEndpoint: '/web-control/stats/ticket/summary/uid',
      },
      statistics: {
        legalPerson: '/web-control/stats/ticket/legal-person',
        legalPersonGrouped: '/web-control/stats/ticket/legal-person/grouped',
        cashRegister: '/web-control/stats/ticket/cash-register',
        cashRegisterGrouped: '/web-control/stats/ticket/cash-register/grouped',
      },
      recovery: {
        commonEndpoint: '/web-control/user',
      },
      currency: {
        createEndpoint: '/web-control/api/currency/create',
        updateEndpoint: '/web-control/api/currency/update',
        deleteEndpoint: (id: number) => `/web-control/api/currency/delete/${id}`,
        listEndpoint: '/web-control/api/currency/list',
        uploadEndpoint: '/web-control/api/currency/legal-person',
      }
    }
  },
  dictionaries: {
    taxSystems: '/web-control/open/tax-systems',
    businessActivities: '/web-control/open/business-activities',
    entrepreneurshipObjects: '/web-control/open/entrepreneurship-objects',
    taxAuthorityDepartments: '/web-control/open/tax-authority-departments',
    legalPersonActivities: '/web-control/open/legal-person-activities',
    formatAndLogicControlErrors: '/web-control/open/fd-error-info',
  },
  tinPattern: '\\d{14}',
  isWithoutOfd: false,
  isUnlimitedFm: true,
  hideCorrections: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
