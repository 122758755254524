<form [formGroup]="registerForm" (ngSubmit)="handleRegister()" class="register_form" *transloco="let t, read: 'common.components.registration'" focusFirstInvalid>

  <div *ngIf="registrationMode === registrationModeEnum.Auto">
    <!-- Surname / Фамилия-->
    <div class="form-item">
      <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6">
        <mat-label>{{t('fields.lastName.label')}}</mat-label>
        <input #lastNameInput matInput type="text" autocomplete="off" formControlName="lastName" clearIfCorrect>
      </mat-form-field>
      <kkm-input-popover *ngIf="isFocused(lastNameInput) && lastName.dirty && lastName.touched && lastName.invalid && lastName.hasError('required')"
                         [text]="t('fields.lastName.errors.required')">
      </kkm-input-popover>
      <kkm-input-popover *ngIf="isFocused(lastNameInput) && lastName.dirty && lastName.touched && lastName.invalid && lastName.hasError('pattern')"
                         [text]="t('fields.lastName.errors.pattern')">
      </kkm-input-popover>
    </div>

    <div class="d-flex">
      <!-- Name / Имя -->
      <div class="form-item m_r-3">
        <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6">
          <mat-label>{{t('fields.firstName.label')}}</mat-label>
          <input #firstNameInput matInput type="text" autocomplete="off" formControlName="firstName" clearIfCorrect>
        </mat-form-field>
        <kkm-input-popover class="zindex-popover"
                           *ngIf="isFocused(firstNameInput) && firstName.dirty && firstName.touched && firstName.invalid && firstName.hasError('required')"
                           [text]="t('fields.firstName.errors.required')">
        </kkm-input-popover>
        <kkm-input-popover class="zindex-popover"
                           *ngIf="isFocused(firstNameInput) && firstName.dirty && firstName.touched && firstName.invalid && firstName.hasError('pattern')"
                           [text]="t('fields.firstName.errors.pattern')">
        </kkm-input-popover>
      </div>

      <!-- Patronymic / Отчество -->
      <div class="form-item m_l-3">
        <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6">
          <mat-label>{{t('fields.middleName.label')}}</mat-label>
          <input #middleNameInput matInput type="text" autocomplete="off" formControlName="middleName" clearIfCorrect>
        </mat-form-field>
        <kkm-input-popover *ngIf="isFocused(middleNameInput) && middleName.dirty && middleName.touched && middleName.invalid && middleName.hasError('required')"
                           [text]="t('fields.middleName.errors.required')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="isFocused(middleNameInput) && middleName.dirty && middleName.touched && middleName.invalid && middleName.hasError('pattern')"
                           [text]="t('fields.middleName.errors.pattern')">
        </kkm-input-popover>
      </div>
    </div>

    <div formGroupName="legalPerson">
      <!-- TIN / ИНН -->
      <div class="form-item">
        <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6" [ngClass]="tin.valid && companyNotFoundError ? 'mat-form-field-invalid' : ''">
          <mat-label>{{t('fields.tin.label')}}</mat-label>
          <input #tinInput matInput type="text" autocomplete="off" formControlName="tin" clearIfCorrect>
        </mat-form-field>
        <kkm-input-popover *ngIf="isFocused(tinInput)
                                  && !tin.value
                                  && !tin.hasError('incorrect')
                                  && tin.dirty
                                  && tin.touched
                                  && tin.invalid"
                           [text]="t('fields.tin.errors.required')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="isFocused(tinInput)
                                  && !companyNotFoundError
                                  && (tin.value || (!tin.value && tin.pristine) || (!tin.value && tin.hasError('incorrect')))"
                           [text]="t('fields.tin.errors.pattern')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="tin.errors?.usedValue"
                           class="register-form__error-popover"
                           [text]="t('fields.tin.errors.not-unique')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="tin.valid
                                  && companyNotFoundError"
                           class="register-form__error-popover"
                           [text]="t('fields.tin.errors.not-found')">
        </kkm-input-popover>
      </div>

      <!-- Company Name / Название компании -->
      <div class="form-item">
        <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6">
          <mat-label>{{t('fields.company-name.label')}}</mat-label>
          <input #companyNameInput matInput type="text" autocomplete="off" formControlName="companyName" clearIfCorrect>
        </mat-form-field>
        <kkm-input-popover *ngIf="isFocused(companyNameInput)
                                  && companyName.dirty
                                  && companyName.touched
                                  && companyName.invalid"
                           [text]="t('fields.company-name.errors.required')">
        </kkm-input-popover>
      </div>
    </div>

    <!-- Email / электронная почта -->
    <div class="form-item">
      <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6">
        <mat-label>{{t('fields.email.label')}}</mat-label>
        <input #emailInput matInput type="text" autocomplete="off" formControlName="login" clearIfCorrect>
      </mat-form-field>
      <kkm-input-popover *ngIf="isFocused(emailInput) && login.dirty && login.touched && login.invalid && login.hasError('required')"
                         [text]="t('fields.email.errors.required')">
      </kkm-input-popover>
      <kkm-input-popover *ngIf="isFocused(emailInput) && login.dirty && login.touched && login.invalid && login.hasError('email')"
                         [text]="t('fields.email.errors.email')">
      </kkm-input-popover>
      <kkm-input-popover *ngIf="isFocused(emailInput) && login.dirty && login.touched && login.invalid && login.hasError('pattern')"
                         [text]="t('fields.email.errors.pattern')">
      </kkm-input-popover>
      <kkm-input-popover *ngIf="isFocused(emailInput) && login.dirty && login.touched && login.invalid && login.hasError('hasSpecialCharacter')"
                         [text]="t('fields.email.errors.special-characters')">
      </kkm-input-popover>
      <kkm-input-popover *ngIf="login.errors?.usedValue" class="register-form__error-popover" [text]="t('fields.email.errors.not-unique')"></kkm-input-popover>
    </div>

    <!-- Password / Пароль -->
    <div class="form-item">
      <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6" [class.eye-button_visible-by-input]="password.value || isFocused(passwordInput)">
        <mat-label>{{t('fields.password.label')}}</mat-label>
        <input #passwordInput matInput [type]="passwordHide ? 'password' : 'text'" autocomplete="off" formControlName="password" clearIfCorrect>
        <button type="button" class="kkm-button eye-button eye-button_visible-by-hover" mat-icon-button matSuffix (click)="passwordHide = !passwordHide">
          <mat-icon [svgIcon]="passwordHide ? 'eye:on' : 'eye:off'"></mat-icon>
        </button>
      </mat-form-field>
      <kkm-input-popover *ngIf="isFocused(passwordInput)
                                && !password.value
                                && password.dirty
                                && password.touched
                                && password.invalid"
                         [text]="t('fields.password.errors.required')">
      </kkm-input-popover>
      <kkm-input-popover *ngIf="isFocused(passwordInput) && (password.value || (!password.value && password.pristine))"
                         [text]="t('fields.password.hint')">
      </kkm-input-popover>
    </div>
  </div>

  <div *ngIf="registrationMode === registrationModeEnum.Manual">
    <div *ngIf="registrationStep === registrationStepEnum.Account">
      <!-- Surname / Фамилия-->
      <div class="form-item">
        <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6">
          <mat-label>{{t('fields.lastName.label')}}</mat-label>
          <input #lastNameInput matInput type="text" autocomplete="off" formControlName="lastName" clearIfCorrect>
        </mat-form-field>
        <kkm-input-popover *ngIf="isFocused(lastNameInput) && lastName.dirty && lastName.touched && lastName.invalid && lastName.hasError('required')"
                           [text]="t('fields.lastName.errors.required')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="isFocused(lastNameInput) && lastName.dirty && lastName.touched && lastName.invalid && lastName.hasError('pattern')"
                           [text]="t('fields.lastName.errors.pattern')">
        </kkm-input-popover>
      </div>

      <div class="d-flex">
        <!-- Name / Имя -->
        <div class="form-item m_r-3">
          <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6">
            <mat-label>{{t('fields.firstName.label')}}</mat-label>
            <input #firstNameInput matInput type="text" autocomplete="off" formControlName="firstName" clearIfCorrect>
          </mat-form-field>
          <kkm-input-popover class="zindex-popover"
                             *ngIf="isFocused(firstNameInput) && firstName.dirty && firstName.touched && firstName.invalid && firstName.hasError('required')"
                             [text]="t('fields.firstName.errors.required')">
          </kkm-input-popover>
          <kkm-input-popover class="zindex-popover"
                             *ngIf="isFocused(firstNameInput) && firstName.dirty && firstName.touched && firstName.invalid && firstName.hasError('pattern')"
                             [text]="t('fields.firstName.errors.pattern')">
          </kkm-input-popover>
        </div>

        <!-- Patronymic / Отчество -->
        <div class="form-item m_l-3">
          <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6">
            <mat-label>{{t('fields.middleName.label')}}</mat-label>
            <input #middleNameInput matInput type="text" autocomplete="off" formControlName="middleName" clearIfCorrect>
          </mat-form-field>
          <kkm-input-popover *ngIf="isFocused(middleNameInput) && middleName.dirty && middleName.touched && middleName.invalid && middleName.hasError('required')"
                             [text]="t('fields.middleName.errors.required')">
          </kkm-input-popover>
          <kkm-input-popover *ngIf="isFocused(middleNameInput) && middleName.dirty && middleName.touched && middleName.invalid && middleName.hasError('pattern')"
                             [text]="t('fields.middleName.errors.pattern')">
          </kkm-input-popover>
        </div>
      </div>

      <!-- Email / электронная почта -->
      <div class="form-item">
        <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6">
          <mat-label>{{t('fields.email.label')}}</mat-label>
          <input #emailInput matInput type="text" autocomplete="off" formControlName="login" clearIfCorrect>
        </mat-form-field>
        <kkm-input-popover *ngIf="isFocused(emailInput) && login.dirty && login.touched && login.invalid && login.hasError('required')"
                           [text]="t('fields.email.errors.required')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="isFocused(emailInput) && login.dirty && login.touched && login.invalid && login.hasError('email')"
                           [text]="t('fields.email.errors.email')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="isFocused(emailInput) && login.dirty && login.touched && login.invalid && login.hasError('pattern')"
                           [text]="t('fields.email.errors.pattern')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="isFocused(emailInput) && login.dirty && login.touched && login.invalid && login.hasError('hasSpecialCharacter')"
                           [text]="t('fields.email.errors.special-characters')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="login.errors?.usedValue" class="register-form__error-popover" [text]="t('fields.email.errors.not-unique')"></kkm-input-popover>
      </div>

      <!-- Password / Пароль -->
      <div class="form-item">
        <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6" [class.eye-button_visible-by-input]="password.value || isFocused(passwordInput)">
          <mat-label>{{t('fields.password.label')}}</mat-label>
          <input #passwordInput matInput [type]="passwordHide ? 'password' : 'text'" autocomplete="off" formControlName="password" clearIfCorrect>
          <button type="button" class="kkm-button eye-button eye-button_visible-by-hover" mat-icon-button matSuffix (click)="passwordHide = !passwordHide">
            <mat-icon [svgIcon]="passwordHide ? 'eye:on' : 'eye:off'"></mat-icon>
          </button>
        </mat-form-field>
        <kkm-input-popover *ngIf="isFocused(passwordInput)
                                && !password.value
                                && password.dirty
                                && password.touched
                                && password.invalid"
                           [text]="t('fields.password.errors.required')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="isFocused(passwordInput) && (password.value || (!password.value && password.pristine))"
                           [text]="t('fields.password.hint')">
        </kkm-input-popover>
      </div>
    </div>

    <div *ngIf="registrationStep === registrationStepEnum.Company" formGroupName="legalPerson">

      <div class="d-flex flex-column text-left m_b-6">
        <span class="muted_text small_text m_b-2" [textContent]="t('fields.type.label')"></span>
        <mat-radio-group aria-labelledby="example-radio-group-label"
                         formControlName="type">
          <mat-radio-button [value]="legalPersonType.Entity" class="m_r-6">{{ t('fields.type.entity') }}</mat-radio-button>
          <mat-radio-button [value]="legalPersonType.Individual">{{ t('fields.type.individual') }}</mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- TIN / ИНН -->
      <div class="form-item">
        <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6" [ngClass]="tin.valid && companyNotFoundError ? 'mat-form-field-invalid' : ''">
          <mat-label>{{t('fields.tin.label')}}</mat-label>
          <input #tinInput matInput type="text" autocomplete="off" formControlName="tin" clearIfCorrect>
        </mat-form-field>
        <kkm-input-popover *ngIf="isFocused(tinInput)
                                  && !tin.value
                                  && !tin.hasError('incorrect')
                                  && tin.dirty
                                  && tin.touched
                                  && tin.invalid"
                           [text]="t('fields.tin.errors.required')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="isFocused(tinInput)
                                  && !companyNotFoundError
                                  && (tin.value || (!tin.value && tin.pristine) || (!tin.value && tin.hasError('incorrect')))"
                           [text]="t('fields.tin.errors.pattern')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="tin.errors?.usedValue"
                           class="register-form__error-popover"
                           [text]="t('fields.tin.errors.not-unique')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="tin.valid
                                  && companyNotFoundError"
                           class="register-form__error-popover"
                           [text]="t('fields.tin.errors.not-found')">
        </kkm-input-popover>
      </div>

      <!-- Company Name / Название компании -->
      <div class="form-item">
        <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6">
          <mat-label>{{t('fields.company-name.label')}}</mat-label>
          <input #companyNameInput matInput type="text" autocomplete="off" formControlName="companyName" clearIfCorrect>
        </mat-form-field>
        <kkm-input-popover *ngIf="isFocused(companyNameInput)
                                  && companyName.dirty
                                  && companyName.touched
                                  && companyName.invalid"
                           [text]="t('fields.company-name.errors.required')">
        </kkm-input-popover>
      </div>

      <!-- Address / Юридический адрес -->
      <div class="form-item">
        <mat-form-field appearance="fill" class="kkm-form-field w-fluid m_b-6">
          <mat-label>{{t('fields.address.label')}}</mat-label>
          <input #addressInput matInput type="text" autocomplete="off" formControlName="legalAddress" clearIfCorrect>
        </mat-form-field>
        <kkm-input-popover *ngIf="isFocused(addressInput)
                                    && legalAddress.dirty
                                    && legalAddress.touched
                                    && legalAddress.invalid"
                           [text]="t('fields.address.errors.required')">
        </kkm-input-popover>
      </div>

      <!-- УГНС -->
      <div class="form-item">
        <mat-form-field class="kkm-form-field w-fluid m_b-6" appearance="fill">
          <mat-label>{{t('fields.tax-authority-department.label')}}</mat-label>
          <input type="text" matInput formControlName="taxAuthorityDepartment"
                 clearIfCorrect
                 existingTaxAuthorityDepartmentCode
                 #taxAuthorityDepartmentsInput
                 #triggerTaxAuthorityDepartments="matAutocompleteTrigger"
                 [matAutocomplete]="taxAuthorityDepartmentAuto"
                 (click)="triggerTaxAuthorityDepartments.openPanel(); $event.target.select();">
          <mat-autocomplete #taxAuthorityDepartmentAuto="matAutocomplete"
                            [displayWith]="displayTaxAuthorityDepartment.bind(this)"
                            (optionSelected)="searchTaxAuthorityDepartments = ''">
            <mat-option *ngFor="let option of filteredTaxAuthorityDepartments; trackById" [value]="option.code">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <kkm-input-popover *ngIf="isFocused(taxAuthorityDepartmentsInput)
                                    && taxAuthorityDepartment.dirty
                                    && taxAuthorityDepartment.touched
                                    && taxAuthorityDepartment.invalid
                                    && taxAuthorityDepartment.hasError('required')"
                           [text]="t('fields.tax-authority-department.errors.required')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="isFocused(taxAuthorityDepartmentsInput)
                                    && taxAuthorityDepartment.dirty
                                    && taxAuthorityDepartment.touched
                                    && taxAuthorityDepartment.invalid
                                    && taxAuthorityDepartment.hasError('codeNotExists')"
                           [text]="t('fields.tax-authority-department.errors.codeNotExists')">
        </kkm-input-popover>
      </div>

      <!-- Виды деятельности -->
      <div class="form-item">
        <mat-form-field class="kkm-form-field w-fluid m_b-6" appearance="fill">
          <mat-label>{{t('fields.legal-person-activity.label')}}</mat-label>
          <input type="text" matInput formControlName="activityCode"
                 clearIfCorrect
                 existingLegalPersonActivityCode
                 #typeOfActivityInput
                 #triggerTypeOfActivity="matAutocompleteTrigger"
                 [matAutocomplete]="typeOfActivityAuto"
                 (click)="triggerTypeOfActivity.openPanel(); $event.target.select();">
          <mat-autocomplete #typeOfActivityAuto="matAutocomplete"
                            [displayWith]="displayActivityCode.bind(this)"
                            (optionSelected)="searchLegalPersonActivities = ''">
            <mat-option *ngFor="let option of filteredLegalPersonActivities; trackById" [value]="option.code">
              {{ option.code + " " + option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <kkm-input-popover *ngIf="isFocused(typeOfActivityInput)
                                    && activityCode.dirty
                                    && activityCode.touched
                                    && activityCode.invalid
                                    && activityCode.hasError('required')"
                           [text]="t('fields.legal-person-activity.errors.required')">
        </kkm-input-popover>
        <kkm-input-popover *ngIf="isFocused(typeOfActivityInput)
                                    && activityCode.dirty
                                    && activityCode.touched
                                    && activityCode.invalid
                                    && activityCode.hasError('codeNotExists')"
                           [text]="t('fields.legal-person-activity.errors.codeNotExists')">
        </kkm-input-popover>
      </div>
    </div>
  </div>

  <div *ngIf="registrationMode === registrationModeEnum.Auto" class="w-fluid">
    <button type="submit"
            class="kkm-button w-fluid"
            mat-button
            color="primary"
            [class.kkm-button_loading]="isLoading"
            [disabled]="isLoading">
      <span *ngIf="!isLoading">{{t('buttons.submit')}}</span>
    </button>
  </div>
  <div *ngIf="registrationMode === registrationModeEnum.Manual">
    <button type="submit"
            class="kkm-button w-fluid"
            mat-button
            color="primary"
            [class.kkm-button_loading]="isLoading"
            [disabled]="isLoading">
      <span *ngIf="!isLoading">{{t(registrationStep === registrationStepEnum.Account ? 'buttons.next' : 'buttons.submit')}}</span>
    </button>
  </div>
</form>
