import {ITranslocoKeyValue} from "@common/interfaces";

export const LANG_LIST = [
  // { value: 'ky', name: 'Кыргызча', langCode: 'ky-KG' },
  { value: 'ru', name: 'Русский', langCode: 'ru-RU' },
  { value: 'en', name: 'English', langCode: 'en-US' }
];

export const DEFAULT_LANG = LANG_LIST[1];

export const EMAIL_REGEX = /^\S{2,}@\S{2,}\.[a-zA-Z]{2,4}$/;

export const GMAP_OPTIONS = {
  zoomControl: true,
  zoomControlOptions: { position: 7 },
  clickableIcons: false,
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  scrollwheel: true,
  disableDoubleClickZoom: true,
  minZoom: 5,
  maxZoom: 18,
  center: { lng: 74.570606, lat: 42.875643 }
};

export const GMAP_ZOOM = 12;
export const PAGE_SIZE = 20;

export const ICON_PATHS = {
  MARKER: {
    ACTIVE: '/assets/markers/mapTaxiMarker.svg',
    DEFAULT: '/assets/markers/mapTaxiMarker.svg'
  }
};

export const RERENDER_TIMEOUT = 15000; // milliseconds
export const USE_CLUSTERS = 12; // google map zoom
export const MIN_DISTANCE = 4000; // minimum distance to create new cluster
export const POLLING_TIMEOUT = 120 * 1000;
export const CURRENCY_FRACTURE_PART = 10000;

export const CURRENCIES: ITranslocoKeyValue[] = [
  { key: "USD", value: "usd"},
  { key: "KGS", value: "kgs"},
  { key: "EUR", value: "eur"},
  { key: "RUB", value: "rub"},
  { key: "KZT", value: "kzt"},
  { key: "CNY", value: "cny"},
];

export const DEFAULT_CURRENCY: ITranslocoKeyValue = CURRENCIES[0];
